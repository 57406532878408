import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/global/layout"
import RegionHero from "../components/regions/region-hero"
import Lead from "../components/global/lead"
import Container from "../components/container/container"
import PillList from "../components/pill/pill-list"
import WineryList from "../components/winery/winery-list"
import ProductSlider from "../components/product/product-slider"

const RegionParent = ({
  data: {
    childRegions,
    datoCmsRegionParent,
    regionWines,
    regionWineries,
    datoCmsRegionsPage,
  },
  pageContext,
}) => {
  const { title, subHeading, description, heroImage, id } =
    datoCmsRegionParent

  const breadcrumbs = [
    { title: datoCmsRegionsPage.title, id: datoCmsRegionsPage.id },
    { title: title, id: id },
  ]

  return (
    <Layout
      seoTitle={`iHeartWine | Explore Wine Regions | ${title}`}
      seoDescription={description}
    >
      <div>
        <RegionHero
          heading={title}
          image={heroImage}
          breadcrumbs={breadcrumbs}
        />
        <Container gutters pt={2} pb={1} width={"small"}>
          <Lead heading={subHeading} text={description} />
        </Container>
        <Container gutters width={"large"}>
          <PillList items={childRegions.nodes} />
        </Container>
        <Container pt={2} pb={1} bg={"light"} width={"none"}>
          <ProductSlider
            heading={`${title} wines`}
            subHeading={"Discover"}
            items={regionWines.nodes}
          />
        </Container>
        <Container gutters width={"large"} pt={2} pb={3}>
          <WineryList items={regionWineries.nodes} title={"Wineries"} />
        </Container>
      </div>
    </Layout>
  )
}

export default RegionParent

export const query = graphql`
  query RegionParentQuery($id: String!, $childRegions: [Int!]) {
    datoCmsRegionsPage {
      id: originalId
      title
    }
    datoCmsRegionParent(originalId: { eq: $id }) {
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      originalId
      id: originalId
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1920", h: "750", fit: "crop", q: 60 }
        )
        alt
      }
      subHeading
      description
    }
    childRegions: allDatoCmsRegionChild(
      filter: { parentRegion: { originalId: { eq: $id } } }
    ) {
      nodes {
        title
        slug
        originalId
        id: originalId
      }
    }
    regionWines: allPortalWine(
      filter: { winery: { region_id: { in: $childRegions } } }
    ) {
      nodes {
        id
        name
        price
        rrp
        discounted_price
        image
        wine_type
        year
        grape {
          name
          id
        }
        winery {
          name
          id
          minimum_wines_per_order
          region_id
          region_name
        }
      }
    }
    regionWineries: allPortalWinery(
      filter: { region_id: { in: $childRegions } }
    ) {
      nodes {
        banner
        address
        name
        id
        wineryId
        state
        country
        description
        region_id
        region_name
      }
    }
  }
`
