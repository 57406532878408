import React from "react"
import style from "./pill-list.mod.scss"
import Navigator from "../global/navigator"
import Pill from "./pill"
import Container from "../container/container"

const PillList = ({ items }) => {
  return (
    <Container width={"large"} gutters>
      <div className={style.list}>
        {items.map((item) => (
          <Navigator recordId={item.id}>
            <Pill type={"region"} text={item.title} />
          </Navigator>
        ))}
      </div>{" "}
    </Container>
  )
}

export default PillList
